<template>
  <v-app>
    <authentication-service-provider
      ref="authenticationServiceProvider"
      v-slot="{ invitedAuthentication, forms, errorMessage, loaders }"
    >
      <div class="invitation-wrapper">

        <!-- Language Selector -->
        <locale-changer class="pb-3" />
        <!-- /Language Selector -->

        <div class="form-wrapper">
          <!-- Title -->
          <div class="title-wrapper">
            <h1>
              {{ $t('auth.signUpInvitedUser.title') }}
            </h1>
            <p>{{ $t('auth.signUpInvitedUser.description') }}</p>
          </div>
          <!-- /Title -->

          <!-- Alert -->
          <v-alert
            v-show="errorMessage"
            border="left"
            color="red"
            type="error"
          >
            {{ errorMessage }}
          </v-alert>
          <!-- /Alert -->

          <validation-observer v-slot="{ invalid }">
            <v-form>
              <validation-provider
                v-slot="{ errors }"
                name="firstName"
                rules="required"
              >
                <v-text-field
                  v-model="forms.invite.firstName"
                  :label="$t('fields.firstName')"
                  outlined
                  required
                  background-color="white"
                  :error-messages="errors"
                  :placeholder="$t('fields.firstName')"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="lastName"
                rules="required"
              >
                <v-text-field
                  v-model="forms.invite.lastName"
                  :label="$t('fields.lastName')"
                  outlined
                  required
                  background-color="white"
                  :error-messages="errors"
                  :placeholder="$t('fields.lastName')"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="password"
                rules="required|min:6"
              >
                <v-text-field
                  v-model="forms.invite.password"
                  :label="$t('auth.global.password')"
                  outlined
                  required
                  autocomplete="new-password"
                  :type="showPassword ? 'text' : 'password'"
                  background-color="white"
                  :error-messages="errors"
                  :placeholder="$t('auth.global.enterPassword')"
                >
                  <template #append>
                    <span @click="showPassword = !showPassword">
                      <v-icon>
                        {{ showPassword ? icons.mdiEye : icons.mdiEyeOff }}
                      </v-icon>
                    </span>
                  </template>
                </v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="confirmPassword"
                rules="required|confirmed:password"
              >
                <v-text-field
                  v-model="forms.invite.repeatPassword"
                  :label="$t('confirmPassword')"
                  outlined
                  required
                  :type="showPassword ? 'text' : 'password'"
                  background-color="white"
                  :error-messages="errors"
                  :placeholder="$t('confirmPassword')"
                >
                  <template #append>
                    <span @click="showPassword = !showPassword">
                      <v-icon>
                        {{ showPassword ? icons.mdiEye : icons.mdiEyeOff }}
                      </v-icon>
                    </span>
                  </template>
                </v-text-field>
              </validation-provider>

              <v-btn
                block
                x-large
                color="primary"
                elevation="0"
                :disabled="invalid || loaders.invite"
                :loading="loaders.invite"
                @click="invitedAuthentication()"
              >
                <span class="font-outfit-semi-bold button-continue">{{ $t('continue') }}</span>
              </v-btn>
            </v-form>
          </validation-observer>

          <!-- Terms & Conditions -->
          <p>{{ $t('auth.global.byContinuing') }} <a
            href="https://ideabuddy.com/terms-of-service/" target="_blank"
          >{{ $t('auth.global.termsAndConditions') }}</a> {{ $t('auth.global.and') }}
            <a href="https://ideabuddy.com/privacy-policy/" target="_blank">{{ $t('auth.global.privacyPolicy') }}</a>.
          </p>
          <!-- /Terms & Conditions -->
        </div>
      </div>

    </authentication-service-provider>
  </v-app>
</template>

<script>
import { mdiEye, mdiEyeOff } from '@mdi/js'
import AuthenticationServiceProvider from '@/components/ServiceProviders/AuthenticationServiceProvider.vue'
import LocaleChanger from '@/components/LocaleChanger.vue'

export default {
  name: 'Invitation',

  components: { LocaleChanger, AuthenticationServiceProvider },

  data () {
    return {
      showPassword: false,
      icons: { mdiEye, mdiEyeOff }
    }
  },

  mounted () {
    this.$refs.authenticationServiceProvider.setInvitationData()
  }
}

</script>

<style scoped lang="scss">
.invitation-wrapper {
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  background: #F5F6FA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #292f4d;

  .form-wrapper {
    width: 100%;
    max-width: 550px;

    .title-wrapper {
      margin-bottom: 40px;

      h1 {
        max-width: 450px;
        margin: 20px auto 0px;
        font-size: 26px;

        @include media-breakpoint-up($sm) {
          font-size: 32px;
        }
      }

      p {
        font-size: 16px;

        @include media-breakpoint-up($sm) {
          font-size: 18px;
        }
      }
    }

    span {
      text-transform: none;
    }

    img {
      width: 19px;
    }

    .button-continue {
      text-transform: uppercase;
    }

    .button-google {
      background: white;

      span {
        color: #292f4d;
      }
    }

    .already-have {
      margin-top: 60px;
    }
  }
}
</style>
